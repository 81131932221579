<a routerLink="/help" routerLinkActive #rlaHelp="routerLinkActive"></a>
<a
  routerLink="/content-management"
  routerLinkActive
  #rlaCms="routerLinkActive"
></a>
<ds-app-wrapper
  [menu]="isImportManager || rlaHelp.isActive ? menu : undefined"
  disablePageHeadlines
>
  <ds-breadcrumbs *ngIf="rlaCms.isActive"></ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
