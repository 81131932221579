import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@shared-lib/language-selection';

@Pipe({
  name: 'language',
  standalone: true,
})
export class LanguagePipe implements PipeTransform {
  constructor(private _languageService: LanguageService) {}

  transform(languageCode: string): string {
    if (!languageCode) {
      return '';
    }
    return this._languageService.findLanguageByCode(languageCode)?.title ?? '';
  }
}
