import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Only used temporary for QA purposes since BPM has no QA environment and therefore we must use BPM TEST env with our QA app,
 * which further means splitting the API Management Subscription keys as well, so we need this additional interceptor
 * to handle only BPM calls and send in a right API Management subscription key from QA to TEST env.
 *
 * REMOVE AFTER BPM GETS IT'S QA ENVIRONMENT!
 */
@Injectable()
export class BpmApiManagementSubscriptionInterceptor
  implements HttpInterceptor
{
  url: string;
  subscriptionKey: string;
  headerName = 'Ocp-Apim-Subscription-Key';

  constructor(
    @Inject(BPM_API_MANAGEMENT_CONFIG) config: BpmApiManagementConfig,
  ) {
    this.url = config.url || '';
    this.subscriptionKey = config.subKey || '';
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.url)) {
      req = req.clone({
        setHeaders: {
          [this.headerName]: `${this.subscriptionKey}`,
        },
      });
    }
    return next.handle(req);
  }
}

export const BPM_API_MANAGEMENT_CONFIG =
  new InjectionToken<BpmApiManagementConfig>('BPM_API_MANAGEMENT_CONFIG');

export interface BpmApiManagementConfig {
  url: string;
  subKey: string;
}
