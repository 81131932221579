import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import {
  AppWrapperModule,
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { DS_DISABLE_SPOTLIGHTS } from '@design-system/feature/spotlight';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    SharedLibFeatTranslationModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appInsightsRole: 'FE: Service Inspection',
          appGatewayBasePath: environment.palfinger_app_gateway,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,

          appName: 'Smart Processes',
          appHelpPath: environment.LINKS.APP_HELP_PATH,
          statusBannerProductsToCheck: ['Smart Processes'],
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    DsPageModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    DsBreadcrumbsModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
  ],
  providers: [
    // SPOTLIGHTS
    {
      provide: DS_DISABLE_SPOTLIGHTS,
      useFactory: () => environment.disable_spotlights,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
