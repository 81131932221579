export const TruckManufacturers = [
  'AMW',
  'AM General',
  'Ashok Leyland',
  'Astra',
  'Autocar',
  'BMC',
  'Caterpillar',
  'Chevrolet',
  'Citroen',
  'Crane Carrier',
  'Daewoo',
  'Daihaitsu',
  'DAF',
  'Dina',
  'Dodge',
  'Eicher',
  'ERF',
  'Farga',
  'Fiat',
  'Foden',
  'Ford',
  'Foton',
  'Freightliner',
  'Fuso',
  'GAZ',
  'Ginaf',
  'GMC',
  'Grumman Olson',
  'Hino',
  'Hyundai',
  'International',
  'Isuzu',
  'Iveco',
  'Jienfang',
  'Kamaz',
  'Kenworth',
  'Liaz',
  'Mack',
  'Magirus',
  'Mahindra',
  'MAN',
  'MAZ',
  'Mazda',
  'Mercedes-Benz',
  'Mitsubishi',
  'Navistar',
  'Nissan',
  'Opel',
  'Oshkosh',
  'Pegaso',
  'Peterbilt',
  'Peugeot',
  'Raba',
  'Ram',
  'Renault',
  'Scania',
  'Sisu',
  'Sterling',
  'Steyr',
  'Tatra',
  'Terberg',
  'Toyota/HINO',
  'UD',
  'Unimog',
  'Ural',
  'Utimec',
  'Volkswagen',
  'Volvo',
  'Western Star',
  'Westmaster',
  'White GMC',
  'Workhorse',
  'Zil',
];
