import { Directive, Self } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatStepper } from '@angular/material/stepper';

@Directive({
  selector: '[dsResponsiveStepper]',
  standalone: true,
})
export class DsResponsiveStepperDirective {
  constructor(
    @Self() stepper: MatStepper,
    private _media: MediaObserver,
  ) {
    this._media?.asObservable()?.subscribe(() => {
      stepper.orientation = this._media.isActive('lt-md')
        ? 'vertical'
        : 'horizontal';
    });
  }
}
