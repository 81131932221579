import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Routes } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard, AuthPermissions } from '@features/auth';

export const appRoutes: Routes = [
  //this has to be at the top of the routes.
  //Since we use a route like this: path: ':inspectionId/:equipmentNumber' for inspection,
  //the special page would be redirect to that route. And we would have something like inspectionId=special and equipmentNumber=pagenotfound (example).
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
    data: {},
  },
  {
    path: '',
    data: {
      breadcrumb: 'breadcrumbs.default',
    },
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('./inspection-overview/inspection-overview.module').then(
            (m) => m.InspectionOverviewModule,
          ),
        canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
        data: {
          permissions: {
            roles: [APPS.SMART_PROCESSES.ROLES.USER],
          } as AuthPermissions,
        },
      },
      {
        path: 'content-management',
        loadChildren: () =>
          import('./content-management/content-management.module').then(
            (m) => m.ContentManagementModule,
          ),
        canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
        data: {
          permissions: {
            roles: [APPS.SMART_PROCESSES.ROLES.IMPORT_MANAGER],
          } as AuthPermissions,
          breadcrumb: 'breadcrumbs.content_management.default',
        },
      },
      {
        path: ':inspectionId/:equipmentNumber',
        loadChildren: () =>
          import('./inspection-process/inspection-process.module').then(
            (m) => m.InspectionProcessModule,
          ),
        canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
      },
      {
        path: 'help',
        loadChildren: () =>
          import('@paldesk/design-system/feature/help-page').then(
            (m) => m.DSHelpPageModule,
          ),
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
