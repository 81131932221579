import { TranslateService } from '@ngx-translate/core';
import { TableSettingsIntl } from '@design-system/components/table-settings';

export class TableSettingsI18n {
  protected prefix: string;

  constructor(
    private readonly translate: TranslateService,
    prefix: string = 'labels.',
  ) {
    this.prefix = prefix;
  }

  getIntl(): TableSettingsIntl {
    const intl = new TableSettingsIntl();

    intl.getColumnName = this.getColumnName.bind(this);

    return intl;
  }

  private getColumnName = (column_name: string) =>
    this.translate.instant(this.prefix + column_name);
}
