export class EnvironmentBase {
  static disable_spotlights = false;
  static environment = '';
  static palfinger_api_management = '';
  static palfinger_app_gateway = '';
  static bpm_api = '';
  static help_pages_api = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_bpm_api_management_subs_key = '';
  static oidc_clientid = '';
  static oidc_scope = '';

  static google_analytics_id = '';
  static application_insights_key = '';

  static domainsForApiManagementSubsKey: string[];

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
    blacklistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
    blacklistedRoutes: [],
  };

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    PRODUCT_REGISTRATION: string;
    CUSTOMER_INVITATION: string;
    HOME: string;
    APP_HELP_PATH: string;
    STATUS_PALDESK_PAGE: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    PRODUCT_REGISTRATION: '',
    CUSTOMER_INVITATION: '',
    HOME: '',
    APP_HELP_PATH: '',
    STATUS_PALDESK_PAGE: '',
  };

  static FEATUREFLAGS: {
    enable_customer_search: string;
    enable_send_handover_confirmation_emails: string;
    enable_smart_records: string;
    enable_save_partner_signature: string;
    use_app_gateway: string;
  } = {
    enable_customer_search: '',
    enable_send_handover_confirmation_emails: '',
    enable_smart_records: '',
    enable_save_partner_signature: '',
    use_app_gateway: '',
  };
}
